import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {dashboardInspectorPagesMenu ,inscrierePageMenu, dashboardPagesMenu,adminPageMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	return (

		<Aside>


			{localStorage.getItem('ContType') === '0'
			?<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				<NavigationLine />
				<Navigation menu={inscrierePageMenu} id='aside-inscriere' />
				<NavigationLine />
			</AsideBody>
			:null
			}

			{localStorage.getItem('ContType') === '1'
			?<AsideBody>
				<Navigation menu={dashboardInspectorPagesMenu} id='aside-dashboard' />
				<NavigationLine />
				{localStorage.getItem('Email') === 'mru@isjcs.ro' 
				?<>
					<Navigation menu={adminPageMenu} id='aside-admin' />
					<NavigationLine />

					</>	
				:null
				}

			</AsideBody>
			:null
			}

{/*				{!doc && (
					<>
						<Navigation menu={demoPagesMenu} id='aside-demo-pages' />
						<NavigationLine />
						<Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' />
						<NavigationLine />
						<nav>
							<div className='navigation'>
								<div className='navigation-item'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<span className='navigation-text'>
												<Popovers
													title='Aside.tsx'
													desc={
														<code>
															src/pages/_layout/_asides/DefaultAside.tsx
														</code>
													}>
													Aside
												</Popovers>
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</>
				)}
*/}			
			<AsideFoot>

				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
