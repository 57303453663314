import React, { FC, ReactNode } from 'react';
import { useMeasure } from 'react-use';

interface IFooterProps {
	children: ReactNode;
}
const Footer: FC<IFooterProps> = ({ children }) => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	return (
		<footer ref={ref} className='footer'>
			<h5>Pentru <strong>suport tehnic</strong> ne puteti contacta la nr. de tel. <strong><span style={{color:"red"}}> 0760114914 </span> Tehnician Platforma E-Titularizare</strong> sau la nr. de tel. <strong><span style={{color:"red"}}> 0724047654 </span>Inspector Scolar MRU Vasile Mandici</strong></h5>
		</footer>
	);
};

export default Footer;
